import React from 'react';
import './assets/sass/ai-main.scss';
import 'remixicon/fonts/remixicon.css'
import RouteIndex from './routes/RouteIndex';
import logo from './assets/images/AlumoLogo.svg';
import {BrowserRouter} from "react-router-dom";

function App() {
      return (
            <div className="app">
                <BrowserRouter>
                  <RouteIndex/>
                </BrowserRouter>
            </div>
      );
}

export default App;
