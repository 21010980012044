import React from 'react';
import {ReactComponent as ButtonIcon} from '../../assets/images/alumo-btn-icon.svg'
import {Link} from "react-router-dom";

function AIButton(props) {
    return (
        <Link to={props.url} className={`ai-button ${props.className ? props.className : ''}`}>
            {props.children}
            {
                props.type === 'ai-saving-btn' &&
                <span className={'ai-btn-icon'}>
                        <ButtonIcon/>
                </span>
            }
        </Link>
    )
}

export default AIButton;