import React , {Fragment} from 'react';
import {
    Router,
    Routes,
    Route, useLocation
} from "react-router-dom";

import Home from '../pages/Home';
import HeinPage from "../pages/HeinPage";
import {AnimatePresence} from "framer-motion";

function RouteIndex() {
    const location = useLocation();
      return (
            // <div>
            //       <Router>
            //             <Fragment>
            //
            //                   <div className="page-wrapper">
            //                       {/* Page Header */}
            //                         {/* Page Content */}
            //                         <div className="page-content">
            //                               <Routes >
            //                                 <Route  path='/' element={<Home/>}></Route>
            //                                 <Route  path='/hein' element={<HeinPage/>}></Route>
            //                               </Routes>
            //                         </div>
            //                          {/* Page Footer */}
            //                   </div>
            //             </Fragment>
            //       </Router>
            // </div>
            <AnimatePresence exitBeforeEnter>
              <Routes location={location} key={location.pathname}>
                  <Route path="/" element={<HeinPage />} />
                  <Route path="/hein" element={<HeinPage/>} />
              </Routes>
            </AnimatePresence>
       );
}

export default RouteIndex;
